const appV2EventPrefix = "HCF Admin Web App (V2):";
const SKILLS_ASSESSMENTS_PREFIX = "Skills Assessments";
const FACILITY_RULES_AI_PREFIX = "Facility Rules AI";
const HOME_HEALTH_EVENT_PREFIX = "Home Health";
const FACILITY_INVOICE_HISTORY_EVENT_PREFIX = "Facility Invoice History";
const INVOICE_DISPUTE_EVENT_PREFIX = "Invoice Dispute";
const ZENDESK_DOCUMENT_PROCESSOR_PREFIX = "Zendesk Document Processor:";
const SHIFT_TIME_PROPOSALS_PREFIX = "Shift Time Proposals";

export const APP_V2_USER_EVENTS = {
  // Skills Assessments
  SKILLS_ASSESSMENTS_PAGE_VIEWED: `${appV2EventPrefix} ${SKILLS_ASSESSMENTS_PREFIX} page viewed`,
  SKILLS_ASSESSMENTS_FETCHED: `${appV2EventPrefix} ${SKILLS_ASSESSMENTS_PREFIX} assessments fetched`,
  SKILLS_ASSESSMENTS_GRADED: `${appV2EventPrefix} ${SKILLS_ASSESSMENTS_PREFIX} assessment graded`,

  // Facility Rules AI
  FACILITY_RULES_AI_VISITED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} page visited`,
  FACILITY_RULES_AI_REVIEW_QUIZ_BANNER_SHOWN: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} review quiz banner shown`,
  FACILITY_RULES_AI_QUIZ_REVIEWED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz reviewed`,
  FACILITY_RULES_AI_NOTES_UPDATED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} notes updated`,
  FACILITY_RULES_AI_QUIZ_UPDATED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz updated`,
  FACILITY_RULES_AI_QUIZ_UPDATE_CANCEL_CLICKED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz update cancel clicked`,
  FACILITY_RULES_AI_QUIZ_UPDATE_CONTINUE_CLICKED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz update continue clicked`,
  FACILITY_RULES_AI_QUIZ_TOGGLED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz toggled`,
  FACILITY_RULES_AI_QUIZ_DRAWER_CLOSED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz drawer closed`,

  FACILITY_RULES_AI_QUIZ_QUESTION_TEXT_REMOVED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz question text removed`,
  FACILITY_RULES_AI_QUIZ_OPTION_TEXT_REMOVED: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} quiz option text removed`,
  FACILITY_RULES_AI_NOTES_CHARACTER_OUT_OF_RANGE_WARNING: `${appV2EventPrefix} ${FACILITY_RULES_AI_PREFIX} notes character out of range warning`,

  // Home Health
  HOME_HEALTH_CASE_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case created`,
  HOME_HEALTH_CASE_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case updated`,
  HOME_HEALTH_CASE_CLOSED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case closed`,
  HOME_HEALTH_CASE_REOPENED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} case reopened`,
  HOME_HEALTH_PATIENT_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} patient created`,
  HOME_HEALTH_PATIENT_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} patient updated`,
  HOME_HEALTH_VISIT_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created`,
  HOME_HEALTH_VISIT_CREATED_WITH_CASE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created with case`,
  HOME_HEALTH_VISIT_CREATED_AFTER_CASE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit created after case`,
  HOME_HEALTH_VISIT_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit updated`,
  HOME_HEALTH_VISIT_CANCELED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit canceled`,
  HOME_HEALTH_VISIT_WORKER_INVITED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker invited`,
  HOME_HEALTH_VISIT_WORKER_BOOKED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker booked`,
  HOME_HEALTH_VISIT_WORKER_REMOVED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit worker removed`,
  HOME_HEALTH_VISIT_OCCURRENCE_REJECTED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence rejected`,
  HOME_HEALTH_VISIT_OCCURRENCE_VERIFIED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence verified`,
  HOME_HEALTH_VISIT_OCCURRENCE_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence updated`,
  HOME_HEALTH_VISIT_OCCURRENCE_PAID: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence set paid`,
  HOME_HEALTH_VISIT_OCCURRENCE_NOT_PAID: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} visit occurrence set not paid`,
  HOME_HEALTH_ACCOUNT_PRICING_CREATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing created`,
  HOME_HEALTH_ACCOUNT_PRICING_UPDATED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing updated`,
  HOME_HEALTH_ACCOUNT_PRICING_DELETED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} account pricing deleted`,
  HOME_HEALTH_TRUSTED_PROVIDER_ADDED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} trusted provider added`,
  HOME_HEALTH_TRUSTED_PROVIDER_REMOVED: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} trusted provider removed`,

  // Facility Invoice History Page
  INVOICE_HISTORY_VIEWED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice History Viewed`,
  INVOICE_VIEW_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice View Clicked`,
  INVOICE_PAY_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Clicked to Pay Invoice`,
  INVOICE_VIEW_DISPUTE_CLICKED: `${appV2EventPrefix} ${FACILITY_INVOICE_HISTORY_EVENT_PREFIX} Invoice View Dispute Button Clicked`,

  // Facility Upcoming Charges
  UPCOMING_CHARGES_VIEWED: `${appV2EventPrefix} Upcoming Charges Viewed`,
  UPCOMING_CHARGES_DATE_RANGE_CHANGED: `${appV2EventPrefix} Upcoming Charges Dates Changed`,
  UPCOMING_CHARGES_VERIFY_SHIFT_POPUP_OPENED: `${appV2EventPrefix} Upcoming Charges Verify Shift Popup Opened`,
  UPCOMING_CHARGES_VERIFY_SHIFT_BUTTON_CLICKED: `${appV2EventPrefix} Upcoming Charges Verify Shift Button Clicked`,

  // Invoice Dispute
  INVOICE_DISPUTE_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Button Clicked`,
  INVOICE_DISPUTE_DRAFT_SAVED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Item Dispute Draft Saved`,
  INVOICE_ITEM_DISPUTE_DISCARD_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Item Discard Button Clicked`,
  INVOICE_DISPUTE_DISCARD_ALL_BUTTON_CLICKED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Discard All Button Clicked`,
  INVOICE_DISPUTE_SUBMITTED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} Invoice Dispute Submitted`,
  INVOICE_DISPUTE_UPDATED_HOURS_PREFERENCE: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} updated dispute time preferences`,
  INVOICE_DISPUTE_BLOCK_NAVIGATION_TRIGGERED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} block navigation triggered`,
  INVOICE_DISPUTE_HOURS_FORMAT_TOOLTIP_CLOSED: `${appV2EventPrefix} ${INVOICE_DISPUTE_EVENT_PREFIX} hours format tooltip closed`,

  // Shift Block
  SHIFT_BLOCK_CREATED: `${appV2EventPrefix} Shift Block Created`,
  SHIFT_BLOCK_DELETED: `${appV2EventPrefix} Shift Block Deleted`,
  SHIFT_BLOCK_UPDATED: `${appV2EventPrefix} Shift Block Updated`,
  SHIFT_BLOCKS: `${appV2EventPrefix} Shift Blocks`,

  // Facility Notes
  WORKER_INSTRUCTIONS_VIEWED: `${appV2EventPrefix} Worker Instructions Viewed`,
  SAVE_FACILITY_RULES_CLICKED: `${appV2EventPrefix} Save facility rules button clicked`,

  // Magic Shifts
  MAGIC_SHIFT_INFO_VIEWED: `${appV2EventPrefix} Magic Shift Info Viewed`,

  // Billing Alerts
  FACILITY_PENDING_NOTICE_ENABLED: `${appV2EventPrefix} Pending notice enabled`,
  FACILITY_SUBMIT_PAYMENT_ALERT_CTA_CLICKED: `${appV2EventPrefix} Submit payment alert CTA clicked`,
  FACILITY_LEARN_MORE_LINK_CLICKED: `${appV2EventPrefix} Learn more link clicked`,

  // Documents
  WORKER_DOCUMENTS_PAGE_VIEWED: `${appV2EventPrefix} Worker documents page viewed`,
  WORKER_DOCUMENTS_PAGE_ACTIONS: `${appV2EventPrefix} Worker documents page actions`,
  WORKER_DOCUMENTS_DOWNLOAD_ALERT_TRIGGERED: `${appV2EventPrefix} Worker document download alert triggered`,
  WORKER_DOCUMENTS_UPDATE_REQUIREMENTS_LINK_CLICKED: `${appV2EventPrefix} Worker document update requirements link clicked`,
  WORKER_DOCUMENTS_WORKER_INFO_COPIED: `${appV2EventPrefix} Worker documents worker info copied`,

  // Login
  KEEP_ME_LOGGED_IN_CLICKED: `${appV2EventPrefix}: Keep me logged in clicked`,

  // Placement Candidates Admin events
  ADMIN_PLACEMENT_CANDIDATE_VIEWED: `${appV2EventPrefix} Admin placement candidate viewed`,
  ADMIN_PLACEMENT_CANDIDATE_UPDATED: `${appV2EventPrefix} Admin placement candidate updated`,
  ADMIN_PLACEMENT_CANDIDATE_RESUME_UPLOADED: `${appV2EventPrefix} Admin placement candidate resume uploaded`,
  ADMIN_PLACEMENT_CANDIDATE_PASSPORT_ENQUEUED: `${appV2EventPrefix} Admin placement candidate passport creation enqueued`,

  // Placement Candidates Hire Professionals page events
  PLACEMENT_CANDIDATES_PAGE_VIEWED: `${appV2EventPrefix} Placement candidates page viewed`,
  PLACEMENT_CANDIDATES_PAGE_FILTER_CHANGED: `${appV2EventPrefix} Placement candidates page filter changed`,
  PLACEMENT_CANDIDATES_TILE_ACTION: `${appV2EventPrefix} Placement candidates tile action`,
  PLACEMENT_CANDIDATES_LOAD_MORE_CLICKED: `${appV2EventPrefix} Placement candidates load more clicked`,
  PLACEMENT_CANDIDATES_CONTACT_INFO_COPIED: `${appV2EventPrefix} Placement candidates contact info copied`,
  PLACEMENT_CANDIDATES_BOOKMARK_TOGGLED: `${appV2EventPrefix} Placement candidates bookmark toggled`,
  PLACEMENT_CANDIDATES_BLOCK_CANDIDATE_TOGGLED: `${appV2EventPrefix} Placement candidates block candidate toggled`,

  // Placement Candidates Details page events
  PLACEMENT_CANDIDATE_DETAILS_VIEWED: `${appV2EventPrefix} Placement candidate details page viewed`,
  PLACEMENT_CANDIDATE_DETAILS_ACTION: `${appV2EventPrefix} Placement candidate details page action`,
  PLACEMENT_CANDIDATE_SHARE_PROFILE_SUBMITTED: `${appV2EventPrefix} Placement candidate details share profile submitted`,
  PLACEMENT_CANDIDATE_OTP_LOGIN: `${appV2EventPrefix} Placement candidate OTP login`,
  PLACEMENT_CANDIDATE_GUEST_FORM_VIEWED: `${appV2EventPrefix} Placement candidate guest form viewed`,
  PLACEMENT_CANDIDATE_GUEST_FORM_ACTION: `${appV2EventPrefix} Placement candidate guest form action`,
  PLACEMENT_CANDIDATE_CREDENTIALS_VIEW_ALL_CLICKED: `${appV2EventPrefix} Placement candidate credentials view all clicked`,
  PLACEMENT_CANDIDATE_CREDENTIAL_VIEWED: `${appV2EventPrefix} Placement candidate credential viewed`,
  PLACEMENT_CANDIDATE_CREDENTIAL_ACTION: `${appV2EventPrefix} Placement candidate credential action`,
  PLACEMENT_CANDIDATE_GET_IN_TOUCH_FORM_ACTION: `${appV2EventPrefix} Placement candidate get in touch form action`,
  PLACEMENT_CANDIDATE_CHAT_ACTION: `${appV2EventPrefix} Placement candidate chat action`,

  // Placement create and update events
  PLACEMENT_PAGE_VIEWED: `${appV2EventPrefix} Placement page viewed`,
  PLACEMENT_CREATED: `${appV2EventPrefix} Placement created`,
  PLACEMENT_UPDATED: `${appV2EventPrefix} Placement updated`,

  // Manage Jobs Tabs
  MANAGE_JOBS_TAB_CHANGED: `${appV2EventPrefix} Manage jobs tab changed`,

  // Placement Applications
  PLACEMENT_APPLICATIONS_PAGE_VIEWED: `${appV2EventPrefix} Placement applications page viewed`,
  PLACEMENT_APPLICATION_STATUS_UPDATED: `${appV2EventPrefix} Placement application status updated`,
  PLACEMENT_APPLICATION_CARD_ACTION: `${appV2EventPrefix} Placement application card action`,

  // Shift invite
  SHIFT_INVITE_CUSTOM_RATE_SUPPORT_LINK_CLICKED: `${appV2EventPrefix} Shift invite custom rate support link clicked`,

  // Attendance Score
  ATTENDANCE_SCORE_RESTRICTION_DELETED: `${appV2EventPrefix} Attendance score restriction deleted`,

  // Shift Signing Out
  FEEDBACK_ON_HCP_WORK_QUALITY: `${appV2EventPrefix} Work quality feedback`,

  // Interview Settings
  INTERVIEW_SETTINGS_PAGE_VIEWED: `${appV2EventPrefix} Interview settings page viewed`,
  INTERVIEW_SETTINGS_UPDATE_SUCCESS: `${appV2EventPrefix} Interview settings updated successfully`,
  INTERVIEW_SETTINGS_UPDATE_FAILURE: `${appV2EventPrefix} Failed to update interview settings`,
  INTERVIEW_SETTINGS_PROSPECT_TOGGLE_CLICKED: `${appV2EventPrefix} Interview settings prospect toggle clicked`,
};

export const APP_V2_APP_EVENTS = {
  // App
  API_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} API Zod Schema validation error`,
  FEATURE_FLAG_SCHEMA_VALIDATION_ERROR: `${appV2EventPrefix} Feature Flag Zod schema validation error`,
  GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to get extra worked time request`,
  PATCH_EXTRA_WORKED_TIME_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to patch extra worked time request`,
  USER_SWITCH_RELOAD: `${appV2EventPrefix} Automatically reloading the page due to user switch`,

  // Documents
  GET_WORKER_DOCUMENTS_FAILURE: `${appV2EventPrefix} Failed to get hcp documents`,
  GET_DOCUMENT_DISTRIBUTION_URL_FAILURE: `${appV2EventPrefix} Failed to get document distribution URL`,
  WORKER_DOCUMENTS_ACTION_FAILURE: `${appV2EventPrefix} Action failed on worker documents page`,
  GET_QUIZ_ATTEMPTS_DOCUMENTS_FAILURE: `${appV2EventPrefix} Failed to get quiz attempts documents`,

  // Licenses
  GET_WORKER_LICENSES_FAILURE: `${appV2EventPrefix} Failed to get worker licenses`,

  // Invoices
  GET_INVOICE_BALANCES_FAILURE: `${appV2EventPrefix} Failed to get invoice balances`,
  GET_INVOICE_HISTORY_FAILURE: `${appV2EventPrefix} Failed to get invoice history`,
  GET_INVOICE_PAYMENT_LINK: `${appV2EventPrefix} Failed to get invoice payment link`,
  GET_BILLING_SPECIALIST_FAILURE: `${appV2EventPrefix} Failed to get facility billing specialist`,
  GET_ACCOUNT_MANAGER_FAILURE: `${appV2EventPrefix} Failed to get facility account manager`,
  GET_TIMESHEET_URL: `${appV2EventPrefix} Failed to get timesheet url`,
  GET_UPCOMING_CHARGES: `${appV2EventPrefix} Failed to get upcoming charges`,
  GET_INVOICE_FAILURE: `${appV2EventPrefix} Failed to get invoice`,
  GET_INVOICE_ITEMS_FAILURE: `${appV2EventPrefix} Failed to get invoice items`,
  GET_INVOICE_ITEM_FAILURE: `${appV2EventPrefix} Failed to get invoice item`,
  GET_INVOICE_DISPUTE_SUMMARY: `${appV2EventPrefix} Failed to get invoice dispute summary`,
  GET_INVOICE_DISPUTES_FAILURE: `${appV2EventPrefix} Failed to get invoices with disputes`,
  GET_INVOICE_TOTAL_FAILURE: `${appV2EventPrefix} Failed to get invoice total`,
  GET_INVOICE_ITEM_DISPUTES_FAILURE: `${appV2EventPrefix} Failed to get invoice item disputes`,
  GET_INVOICE_ID_FAILURE: `${appV2EventPrefix} Failed to get invoice id`,
  INVOICE_TRANSFER_FAILURE: `${appV2EventPrefix} Failed to transfer invoice`,

  // Facility
  // TODO(clipboardhealth.atlassian.net/browse/TX-761): Remove const usage after the complete rollout and flag removal
  GET_FACILITY_SOLVE_UNPAID_WORKED_BREAKS_STAGE: `${appV2EventPrefix} Failed to load facility SolveWorkedUnpaidBreaks stage`,
  GET_FACILITY_STREAKS_FAILURE: `${appV2EventPrefix} Failed to get facility streaks`,
  GET_WORKER_DETAILS_AT_WORKPLACE_FAILRE: `${appV2EventPrefix} Failed to get worker details at workplace`,
  GET_EXCLUSION_FAILURE: `${appV2EventPrefix} Failed to get exclusion`,
  GET_FACILITY_NOTES_FAILURE: `${appV2EventPrefix} Failed to get facility notes`,
  GET_QUIZ_GENERATION_STATUS_FAILURE: `${appV2EventPrefix} Failed to get quiz generation status`,
  GET_FACILITY_USERS_FAILURE: `${appV2EventPrefix} Failed to get facility users`,
  GET_USER_FAILURE: `${appV2EventPrefix} Failed to get user`,
  GET_FACILITIES: `${appV2EventPrefix} Failed to get facilities`,
  GET_CHILD_WORKPLACES: `${appV2EventPrefix} Failed to get child workplaces`,

  // Agent
  GET_PREFERRED_WORKERS_FAILURE: `${appV2EventPrefix} Failed to get preferred workers`,
  GET_AGENT_PROFILE_FAILURE: `${appV2EventPrefix} Failed to load agent profile`,
  GET_AGENT_SEARCH_FAILURE: `${appV2EventPrefix} Failed to search agent`,

  // Timezone
  GET_AGENT_PROFILE_PICTURE_FAILURE: `${appV2EventPrefix} Failed to get agent profile picture`,
  GET_TIMEZONE_FAILURE: `${appV2EventPrefix} Failed to get timezone`,

  // Facility Profile
  GET_FACILITY_TIME_CARD_TEMPLATE_FAILURE: `${appV2EventPrefix} Failed to get facility time card template`,
  GET_FACILITY_PROFILE_FAILED: `${appV2EventPrefix} Failed to get facility profile`,
  GET_FACILITY_DETAILS_FAILURE: `${appV2EventPrefix} Failed to get facility details`,

  // Qualifications
  GET_QUALIFICATIONS_FAILURE: `${appV2EventPrefix} Failed to get qualifications`,
  GET_QUALIFICATION_FAILURE: `${appV2EventPrefix} Failed to get qualification`,
  GET_WORKER_TYPES_FAILURE: `${appV2EventPrefix} Failed to get worker types`,

  // Skill Assessments
  GET_SKILL_ASSESSMENTS_FAILURE: `${appV2EventPrefix} Failed to get skill assessments`,

  // Workplace Types
  GET_WORKPLACE_TYPES_FAILURE: `${appV2EventPrefix} Failed to get workplace types`,
  GET_WORKPLACE_TYPE_FAILURE: `${appV2EventPrefix} Failed to get workplace type`,

  // User
  SEARCH_USERS_FAILURE: `${appV2EventPrefix} Failed to search users`,

  SEARCH_AGENTS_FAILURE: `${appV2EventPrefix} Failed to search users`,
  SEARCH_FACILITIES_FAILURE: `${appV2EventPrefix} Failed to search facilities`,

  GET_FACILITY_STREAKS_SETTINGS_FAILURE: `${appV2EventPrefix} Failed to get facility streaks settings`,

  // Home Health
  HOME_HEALTH_ACCOUNTS_PRICING_GET_LIST_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get accounts pricing list`,
  HOME_HEALTH_GET_WORKER_ENABLED_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get worker enabled status`,
  HOME_HEALTH_WORKPLACE_WORKER_STATISTICS_GET_FAILURE: `${appV2EventPrefix} ${HOME_HEALTH_EVENT_PREFIX} Failed to get workplace worker statistics`,

  // Auth
  AUTH_TOKEN_NOT_AVAILABLE: `${appV2EventPrefix} Auth token not available`,
  SIGN_IN_WITH_EMAIL_LINK_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with email link via Firebase`,
  SIGN_IN_WITH_CUSTOM_TOKEN_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with custom token via Firebase`,
  SIGN_IN_WITH_EMAIL_PASSWORD_FIREBASE_FAILURE: `${appV2EventPrefix} Failed to sign in with email and password via Firebase`,

  // Magic Shifts
  SET_SHIFT_URGENT_FAILURE: `${appV2EventPrefix} Failed to set shift as urgent`,
  SET_SHIFT_NON_URGENT_FAILURE: `${appV2EventPrefix} Failed to stop looking for replacement`,
  GET_FACILITY_DAY_SHIFTS_FAILURE: `${appV2EventPrefix} Failed to get facility day shifts`,
  GET_MAGIC_POINT_BALANCE_FAILURE: `${appV2EventPrefix} Failed to get magic point balance`,
  GET_MAGIC_POINT_RATE_FAILURE: `${appV2EventPrefix} Failed to get magic point rate`,

  // Placement Candidate
  UPDATE_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to update placement candidate`,
  GET_OR_CREATE_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to get and create placement candidate`,
  GET_PLACEMENT_CANDIDATE_CREDENTIAL_URL_FAILURE: `${appV2EventPrefix} Failed to get placement candidate credential url`,
  GET_PLACEMENT_CREDENTIALS: `${appV2EventPrefix} Failed to get placement credentials`,
  ENQUEUE_PASSPORT_GENERATION_FOR_PLACEMENT_CANDIDATE_FAILURE: `${appV2EventPrefix} Failed to enqueue passport generation for a placement candidate`,

  // Placements
  PLACEMENT_CREATE_FAILURE: `${appV2EventPrefix} Placement create failure`,
  PLACEMENT_UPDATE_FAILURE: `${appV2EventPrefix} Placement update failure`,
  PLACEMENT_NOTIFY_WORKPLACE_LEAD_FAILURE: `${appV2EventPrefix} Placement notify workplace lead failure`,
  // Placement Applications
  PLACEMENT_APPLICATION_UPDATE_FAILURE: `${appV2EventPrefix} Placement application update failure`,
  GET_PLACEMENT_FAILURE: `${appV2EventPrefix} Failed to get placement`,
  GET_PLACEMENT_APPLICATION_SUMMARY_FAILURE: `${appV2EventPrefix} Failed to get placement application summary`,
  PLACEMENT_APPLICATION_AUTO_INVITE_CANCEL_FAILURE: `${appV2EventPrefix} Failed to cancel placement application auto invite`,
  PLACEMENT_APPLICATION_AUTO_INVITE_SEND_FAILURE: `${appV2EventPrefix} Failed to send placement application auto invite`,

  // Quiz
  GET_IS_QUIZ_REQUIRED_FAILURE: `${appV2EventPrefix} Failed to get if quiz is required`,
  GET_FACILITY_QUIZ_FAILURE: `${appV2EventPrefix} Failed to get workplace quiz`,

  // Request To Book
  GET_BOOKING_REQUESTS_FAILURE: `${appV2EventPrefix} Failed to get booking requests`,
  UPDATE_BOOKING_REQUEST_FAILURE: `${appV2EventPrefix} Failed to update booking request`,

  // Rankings
  GET_RANKINGS_FAILURE: `${appV2EventPrefix} Failed to get ranking of workers`,
  GET_RANKING_NUDGES_FAILURE: `${appV2EventPrefix} Failed to get ranking nudges`,
  DELETE_RANKING_SUCCESS: `${appV2EventPrefix} Ranking removed successfully`,
  DELETE_RANKING_FAILURE: `${appV2EventPrefix} Failed to delete ranking`,
  CREATE_RANKING_SUCCESS: `${appV2EventPrefix} Ranking added successfully`,
  CREATE_RANKING_FAILURE: `${appV2EventPrefix} Failed to add ranking`,
  RANKING_NUDGE_SHOWN: `${appV2EventPrefix} Ranking nudge shown`,
  RANKING_NUDGE_ACTION: `${appV2EventPrefix} Ranking nudge action`,

  // Access Window
  GET_ACCESS_WINDOW_FAILURE: `${appV2EventPrefix} Failed to get access window for a unfilled shift`,

  // Nearby Workers
  GET_NEARBY_WORKERS_FAILURE: `${appV2EventPrefix} Failed to get nearby workers`,

  // Requirement
  GET_REQUIREMENT_FAILURE: `${appV2EventPrefix} Failed to get requirement`,
  GET_REQUIREMENTS_FAILURE: `${appV2EventPrefix} Failed to get requirements`,

  //Zendesk Document Processor
  ZENDESK_DOCUMENT_PROCESSOR_UPDATE_DOCUMENT_ERROR: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Update Document Error`,
  ZENDESK_DOCUMENT_PROCESSOR_DOCUMENT_REJECTED: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Document Rejected`,
  ZENDESK_DOCUMENT_PROCESSOR_DOCUMENT_APPROVED: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Document Approved`,
  ZENDESK_DOCUMENT_PROCESSOR_DOCUMENT_VIEW_OPENED: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Document View Opened`,
  ZENDESK_DOCUMENT_PROCESSOR_UPDATE_LICENSE_ERROR: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Update License Error`,
  ZENDESK_DOCUMENT_PROCESSOR_LICENSE_ACTION: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} License View Action`,
  ZENDESK_DOCUMENT_PROCESSOR_ADD_REQUIREMENT: `${appV2EventPrefix} ${ZENDESK_DOCUMENT_PROCESSOR_PREFIX} Add Requirement`,

  NON_EXISTING_ICON_REQUESTED: `${appV2EventPrefix} Non existing icon requested`,

  POST_CREATE_CHAT_CHANNEL_FAILURE: `${appV2EventPrefix} Failed to create the channel`,

  // Shift Signing Out
  CREATE_SHIFT_SIGNING_OUT_FEEDBACK_FAILURE: `${appV2EventPrefix} Error while trying to create shift signing out feedback`,
  UPLOAD_SIGNER_SIGNATURE_FAILURE: `${appV2EventPrefix} Error while trying to upload feedback signer signature`,
  QR_CODE_SIGNATURE_SUBMIT_TIME_SHEET_FAILURE: `${appV2EventPrefix} Error while trying to submit shift time sheet with QR code signature`,
  SHIFT_SIGNING_OUT_FEEDBACK_SUBMIT_FAILURE: `${appV2EventPrefix} Error on finalizing shift signing out feedback`,

  // Shift
  GET_SHIFT_FAILURE: `${appV2EventPrefix} Failed to get shift`,
  GET_SHIFTS_LIST_FAILURE: `${appV2EventPrefix} Failed to get shifts`,

  // Agreements
  GET_SIGNED_AGREEMENTS_FAILURE: `${appV2EventPrefix} Failed to get signed agreements`,

  // Shift Signout Flow
  GET_SHIFT_FEEDBACK_FAILURE: `${appV2EventPrefix} Failed to get shift feedback`,

  // Worker Left Early
  GET_WORKER_LEFT_EARLY_FAILURE: `${appV2EventPrefix} Failed to get worker left early`,
};

export const SHIFT_TIME_PROPOSAL_EVENTS = {
  SHIFT_TIME_PROPOSAL_CREATED: `${appV2EventPrefix} ${SHIFT_TIME_PROPOSALS_PREFIX} proposal created`,
  SHIFT_TIME_PROPOSAL_UPDATED: `${appV2EventPrefix} ${SHIFT_TIME_PROPOSALS_PREFIX} proposal updated`,
};
